var Homepage = {
    init: function(){
        Homepage._initAll();
        $(window).resize(function(){
            waitForFinalEvent(
                function(){
                    Homepage._initAll();
                }, 
                500, 
                'initHomepage'
            );
        });
    },
    
    _initAll: function(){              
        $owlHpPromotions = $('#promotions-slider .owl-carousel')
            .owlCarousel({
                items: 1,
                startPosition: 2,
                loop: true,
                nav : true, // Show next and prev buttons,
                navText : ["<span></span>","<span></span>"],
                autoplay: true,
                autoplaySpeed: 1000,
                autoplayTimeout: 7000,
                navSpeed: 1000,
                dotsSpeed: 1000,
                dots: true,
                onInitialized: function(event){
                    $(event.target)
                        .addClass('owl-my-loaded')
                    ;
                },
                onChanged: function(event){
                    
                }
            })
        ;

        setTimeout(
            function(){
                $('#promotions-slider .owl-dot').on('click',function () {
                    $owlHpPromotions.trigger('to.owl.carousel', [$(this).index(), 1000]);
                });
            },
            1000);
        ;    
    }
};

$(function() {
    Homepage.init();
});
